import { useMemo } from "react";
import { EstimatesValue, IdeaDto } from "api-shared";
import { groupBy } from "lodash";
import { useCurrentClient } from "../../../domain/client";

export const useIdeaPotentialSum = (ideas: IdeaDto[] | undefined): number => {
    const { ideaPotentialIntervals } = useCurrentClient();

    return useMemo(() => {
        if (!ideas) {
            return 0;
        }

        const groupedIdeas = groupBy(ideas, (idea) => idea.potentialEstimate);

        const initialInterval = ideaPotentialIntervals[0];
        const firstInterval = ideaPotentialIntervals[1];
        const secondInterval = ideaPotentialIntervals[2];
        const thirdInterval = ideaPotentialIntervals[3];
        const fourthInterval = ideaPotentialIntervals[4];

        const veryLowSum = (groupedIdeas[EstimatesValue.VERY_LOW]?.length ?? 0) * ((firstInterval + initialInterval) / 2);
        const lowSum = (groupedIdeas[EstimatesValue.LOW]?.length ?? 0) * ((secondInterval + firstInterval) / 2);
        const mediumSum = (groupedIdeas[EstimatesValue.MEDIUM]?.length ?? 0) * ((thirdInterval + secondInterval) / 2);
        const highSum = (groupedIdeas[EstimatesValue.HIGH]?.length ?? 0) * ((fourthInterval + thirdInterval) / 2);
        const veryHighSum = (groupedIdeas[EstimatesValue.VERY_HIGH]?.length ?? 0) * fourthInterval;

        return veryLowSum + lowSum + mediumSum + highSum + veryHighSum;
    }, [ideas, ideaPotentialIntervals]);
};
