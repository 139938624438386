import { Card, Grid } from "@mui/material";
import { IdeaDto, IdeaSortBy, Sort } from "api-shared";
import { groupBy, orderBy, sortBy } from "lodash";
import moment from "moment";
import React from "react";
import IdeaSortContainerRow from "./IdeaSortContainerRow";
import { IdeasSummaryGrid } from "./IdeasSummaryGrid";
import { IUseIdeaEstimateConversion, useIdeaEstimateConversion } from "./hooks";

export interface IIdeaSortContainerProps {
    filteredIdeas: IdeaDto[];
    orderBy: IdeaSortBy;
    sort: Sort;
    isFetching: boolean;
}

/**
 * sortValue may be one of the following
 * - stringified Date value, e.g. "Jun 2023"
 * - stringified null value if the grouped value has been null: "null"
 * - stringified EstimatesValue: e.g. "10", "20", ...
 *
 * @param {IdeaSortBy} ideaSortBy
 * @param {string} sortValue stringified group by value because it has been used as object key
 * @param {(input: IUseIdeaEstimateConversion) => { potentialValue: string; timeValue: string; effortValue: string }} getIdeaConversionValues
 * @returns
 */
const sortIdeaLabel = (
    ideaSortBy: IdeaSortBy,
    sortValue: string,
    getIdeaConversionValues: (input: IUseIdeaEstimateConversion) => { potentialValue: string; timeValue: string; effortValue: string },
) => {
    if (ideaSortBy === IdeaSortBy.CREATED_AT || ideaSortBy === IdeaSortBy.LAST_MODIFICATION_AT) {
        // sortValue is a stringified Date value, e.g. "Jun 2023"
        // nothing to do
        return sortValue;
    }

    const parsedValue = sortValue === "null" ? null : +sortValue;
    const { potentialValue, timeValue, effortValue } = getIdeaConversionValues({
        potentialEstimateValue: parsedValue,
        timeEstimateValue: parsedValue,
        effortEstimateValue: parsedValue,
    });
    switch (ideaSortBy) {
        case IdeaSortBy.POTENTIAL:
            return potentialValue;
        case IdeaSortBy.TIME:
            return timeValue;
        case IdeaSortBy.EFFORT:
            return effortValue;
    }
};

const customGroup = (value: IdeaDto, ideaSortBy: IdeaSortBy) => {
    switch (ideaSortBy) {
        case IdeaSortBy.CREATED_AT: {
            const temp = moment(value.createdAt);
            return temp.format("MMMM YYYY");
        }
        case IdeaSortBy.LAST_MODIFICATION_AT: {
            const temp = moment(value.lastModificationAt);
            return temp.format("MMMM YYYY");
        }
        case IdeaSortBy.POTENTIAL:
            return value.potentialEstimate;
        case IdeaSortBy.TIME:
            return value.timeEstimate;
        case IdeaSortBy.EFFORT:
            return value.effortEstimate;
    }
};
const sortIdeaList = (
    ideas: IdeaDto[],
    ideaOrderBy: IdeaSortBy,
    sort: Sort,
    getIdeaConversionValues: (input: IUseIdeaEstimateConversion) => { potentialValue: string; timeValue: string; effortValue: string },
) => {
    // Total order is given by the selected sorting
    const orderedIdeas = orderBy(
        ideas,
        // Fallback to 0 so that null values will be handled as smallest values
        [(item) => item[ideaOrderBy] ?? 0, "createdAt"],
        [sort === Sort.ASCENDING ? "asc" : "desc", "desc"],
    );

    // grouping adds additional visual indicators on top, but should not change sorting/ordering
    // (similar image galleries on smartphone apps)
    const groupedIdeas = groupBy(orderedIdeas, (idea) => customGroup(idea, ideaOrderBy));

    // Unfortunately, the order of groups is not the order of their appearance in the source array
    // Fix that by sorting the groups ascending by their appearance in the ordered ideas
    const orderedGroups = sortBy(Object.entries(groupedIdeas), ([key, group]) => orderedIdeas.indexOf(group[0]));

    return orderedGroups.map(([key, group]) => ({
        key: key,
        data: group,
        label: sortIdeaLabel(ideaOrderBy, key, getIdeaConversionValues),
    }));
};

const IdeaSortContainer = ({ filteredIdeas, orderBy, sort, isFetching }: IIdeaSortContainerProps) => {
    const getIdeaConversionValues = useIdeaEstimateConversion();
    const sortedIdeas = sortIdeaList(filteredIdeas, orderBy, sort, getIdeaConversionValues);
    return (
        <Grid>
            <Card sx={{ mb: 1 }}>
                <IdeasSummaryGrid isFetching={isFetching} ideas={filteredIdeas} />
            </Card>
            <Grid container spacing={1} alignItems="stretch">
                {!isFetching ? (
                    sortedIdeas.map((ideaRow, key) => <IdeaSortContainerRow key={key} label={ideaRow.label} ideaRow={ideaRow.data} />)
                ) : (
                    <IdeaSortContainerRow />
                )}
            </Grid>
        </Grid>
    );
};

export default React.memo(IdeaSortContainer);
