import ArrowDropdownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { Box, ListItemText, Menu, MenuItem, styled } from "@mui/material";
import { IdeaSortBy, Sort } from "api-shared";
import { useTranslation } from "react-i18next";
import DefaultButton from "../../components/DefaultButton";
import { Option } from "../../components/input/select/types";
import useMenu from "../../hooks/useMenu";
import { translationKeys } from "../../translations/main-translations";
import { IIdeaSortSelectOption } from "./IdeasView";

const BorderlessButton = styled(DefaultButton)(({ theme }) => ({
    border: "none",
    fontWeight: theme.typography.fontWeightRegular,
}));

const GreyArrowDropdownRoundedIcon = styled(ArrowDropdownRoundedIcon)(({ theme }) => ({
    color: theme.palette.fontLightGrey.main,
}));

interface IdeaSortSelectProps {
    value: IIdeaSortSelectOption;
    onChange?: (newValue: IIdeaSortSelectOption) => void;
    disabled?: boolean;
}

const labelsMap = new Map([
    [{ sortType: IdeaSortBy.CREATED_AT, sort: Sort.DESCENDING }, translationKeys.VDLANG_IDEAS_SORT_BY_CREATED_DESC],
    [{ sortType: IdeaSortBy.CREATED_AT, sort: Sort.ASCENDING }, translationKeys.VDLANG_IDEAS_SORT_BY_CREATED_ASC],
    [{ sortType: IdeaSortBy.TIME, sort: Sort.ASCENDING }, translationKeys.VDLANG_IDEAS_SORT_BY_TIME_ASC],
    [{ sortType: IdeaSortBy.TIME, sort: Sort.DESCENDING }, translationKeys.VDLANG_IDEAS_SORT_BY_TIME_DESC],
    [{ sortType: IdeaSortBy.POTENTIAL, sort: Sort.ASCENDING }, translationKeys.VDLANG_IDEAS_SORT_BY_POTENTIAL_ASC],
    [{ sortType: IdeaSortBy.POTENTIAL, sort: Sort.DESCENDING }, translationKeys.VDLANG_IDEAS_SORT_BY_POTENTIAL_DESC],
    [{ sortType: IdeaSortBy.EFFORT, sort: Sort.ASCENDING }, translationKeys.VDLANG_IDEAS_SORT_BY_EFFORT_ASC],
    [{ sortType: IdeaSortBy.EFFORT, sort: Sort.DESCENDING }, translationKeys.VDLANG_IDEAS_SORT_BY_EFFORT_DESC],
    [{ sortType: IdeaSortBy.LAST_MODIFICATION_AT, sort: Sort.ASCENDING }, translationKeys.VDLANG_IDEAS_SORT_BY_LAST_MODIFICATION_ASC],
    [{ sortType: IdeaSortBy.LAST_MODIFICATION_AT, sort: Sort.DESCENDING }, translationKeys.VDLANG_IDEAS_SORT_BY_LAST_MODIFICATION_DESC],
]);

const IdeaSortSelect = ({ value, onChange, disabled }: IdeaSortSelectProps) => {
    const { t: translate } = useTranslation();
    const menu = useMenu();

    const options = Array.from(labelsMap).map(([optionValue, translationKey]) => ({
        value: optionValue,
        label: translate(translationKey),
    }));

    const handleChange = (option: Option<IIdeaSortSelectOption>) => {
        onChange?.(option.value);
        menu.close();
    };

    const selectedOption = options.find((o) => o.value.sortType === value.sortType && o.value.sort === value.sort);
    const selectedOptionLabel = selectedOption !== undefined ? selectedOption.label : ""; // selectedOption should never be undefined

    return (
        <Box>
            <Menu {...menu.menuProps}>
                {options.map((option) => (
                    <MenuItem key={option.label} selected={option.label === selectedOption?.label} onClick={() => handleChange(option)}>
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </Menu>
            <BorderlessButton
                sx={{ height: (theme) => theme.spacing(2.5) }}
                color="inherit"
                onClick={menu.open}
                endIcon={<GreyArrowDropdownRoundedIcon />}
                disabled={disabled}
            >
                {selectedOptionLabel}
            </BorderlessButton>
        </Box>
    );
};

export default IdeaSortSelect;
