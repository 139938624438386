import ArrowDropdownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { Box, Button, ListItemText, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { IdeaXAxis } from "api-shared";
import { useTranslation } from "react-i18next";
import { Option } from "../../../components/input/select/types";
import useMenu from "../../../hooks/useMenu";
import { translationKeys } from "../../../translations/main-translations";

interface IIdeaMatrixSelect {
    value: IdeaXAxis;
    onChange?: (newValue: IdeaXAxis) => void;
}

const labelsMap = new Map([
    [IdeaXAxis.TIME, translationKeys.VDLANG_IDEAS_SEARCH_TIME],
    [IdeaXAxis.EFFORT, translationKeys.VDLANG_IDEAS_EFFORT_ESTIMATE_LABEL],
]);

const IdeaMatrixSelect = ({ value, onChange }: IIdeaMatrixSelect) => {
    const { t: translate } = useTranslation();
    const menu = useMenu();

    const options = Array.from(labelsMap).map(([optionValue, translationKey]) => ({
        value: optionValue,
        label: translate(translationKey),
    }));

    const handleChange = (option: Option<IdeaXAxis>) => {
        onChange?.(option.value);
        menu.close();
    };

    const selectedOption = options.find((o) => o.value === value);
    const selectedOptionLabel = selectedOption !== undefined ? selectedOption.label : "";

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography align="center">{translate(translationKeys.VDLANG_IDEAS_MATRIX_X_AXIS)}:</Typography>
                <Box>
                    <Menu {...menu.menuProps}>
                        {options.map((option) => (
                            <MenuItem
                                key={option.label}
                                selected={option.label === selectedOption?.label}
                                onClick={() => handleChange(option)}
                            >
                                <ListItemText primary={option.label} />
                            </MenuItem>
                        ))}
                    </Menu>
                    <Button variant="text" color="inherit" onClick={menu.open} endIcon={<ArrowDropdownRoundedIcon />} sx={{ p: 0 }}>
                        {selectedOptionLabel}
                    </Button>
                </Box>
            </Stack>
        </>
    );
};
export default IdeaMatrixSelect;
