import { Card, Grid, Paper, Stack, Typography } from "@mui/material";
import { IdeaDto, IdeaXAxis } from "api-shared";
import { useTranslation } from "react-i18next";
import { useUiState } from "../../domain/ui-state";
import { translationKeys } from "../../translations/main-translations";
import { IdeasSummary } from "./IdeasSummary";
import { useIdeaMatrixFilter } from "./hooks/useIdeaMatrixFilter";
import { IdeaMatrix } from "./matrix/IdeaMatrix";
import IdeaMatrixSelect from "./matrix/IdeaMatrixSelect";

interface IdeasViewMatrixProps {
    ideas: IdeaDto[];
    isFetching: boolean;
}

const IdeasViewMatrixHeader = ({
    title,
    axis,
    onChangeAxis,
}: {
    title: string;
    axis: IdeaXAxis;
    onChangeAxis: (axis: IdeaXAxis) => void;
}) => {
    return (
        <Grid container justifyContent="space-between" sx={{ p: 3, pb: 2 }}>
            <Grid item>
                <Typography variant="subtitle1" sx={{ p: 0 }}>
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                <IdeaMatrixSelect value={axis} onChange={onChangeAxis} />
            </Grid>
        </Grid>
    );
};

const IdeasViewMatrix = ({ ideas, isFetching }: IdeasViewMatrixProps) => {
    const { t: translate } = useTranslation();
    const [uiState, updateUiState] = useUiState();
    const { visibleIdeas, numberOfHiddenIdeas } = useIdeaMatrixFilter(ideas, uiState.opportunitiesMatrixXAxis);

    const updateMatrixAxis = (ideaXAxis: IdeaXAxis) => {
        updateUiState({ opportunitiesMatrixXAxis: ideaXAxis });
    };

    return (
        <Stack spacing={1}>
            <Card>
                <IdeasSummary isFetching={isFetching} ideas={visibleIdeas} numberOfHiddenIdeas={numberOfHiddenIdeas} />
            </Card>
            <Paper>
                <Stack>
                    <IdeasViewMatrixHeader
                        title={translate(translationKeys.VDLANG_IDEAS_MATRIX_HEADER)}
                        axis={uiState.opportunitiesMatrixXAxis}
                        onChangeAxis={updateMatrixAxis}
                    />
                    <IdeaMatrix
                        sx={{ m: 3, mt: 0 }}
                        ideas={visibleIdeas}
                        isFetching={isFetching}
                        xAxis={uiState.opportunitiesMatrixXAxis}
                        chartHeight={500}
                    />
                </Stack>
            </Paper>
        </Stack>
    );
};

export default IdeasViewMatrix;
