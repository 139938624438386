import { zNumericId } from "api-shared";
import { useMemo } from "react";
import { z } from "zod";
import { useDebounce } from "../../../hooks/useDebounce";

const DEFAULT_MIN_LENGTH = 3;

export function useIdeaDebouncedSearchKey(searchKey: string, minLength = DEFAULT_MIN_LENGTH) {
    const zValidator = useMemo(() => zNumericId.positive().or(z.string().min(minLength)), [minLength]);
    return useDebounce(zValidator.safeParse(searchKey).success ? searchKey : "");
}
