import { Stack } from "@mui/material";
import { AttributeType, EstimatesValue, IdeaStatus } from "api-shared";
import { TFunction } from "i18next";
import React, { useCallback } from "react";
import { useIdeaAttributesQuery } from "../../domain/ideas";
import { useUiState } from "../../domain/ui-state";
import { translationKeys } from "../../translations/main-translations";
import { IdeaEstimateFilter } from "./IdeaEstimateFilter";
import IdeaFieldFilter from "./IdeaFieldFilter";
import { IdeaStatusFilter } from "./IdeaStatusFilter";
import IdeaUserSelectFilter from "./IdeaUserSelectFilter";

const EMPTY_ARRAY = [] as string[];
const VALID_FILTER_TYPES = [String(AttributeType.Combobox), String(AttributeType.Tree)];

interface IIdeasViewFilterHeader {
    translate: TFunction;
}

const IdeasViewFilterHeader = ({ translate }: IIdeasViewFilterHeader) => {
    const [uiState, updateUiState] = useUiState();
    const ideaAttributesQuery = useIdeaAttributesQuery();

    const handleSearchCreatorIdsChange = useCallback(
        (value: ReadonlyArray<number>) => updateUiState({ ideaSearchCreatedByIds: [...value] }),
        [updateUiState],
    );

    const handleSearchOwnerIdsChange = useCallback(
        (value: ReadonlyArray<number>) => updateUiState({ ideaSearchOwnedByIds: [...value] }),
        [updateUiState],
    );

    const handleSearchPotentialEstimateChange = useCallback(
        (value: (EstimatesValue | null)[]) => updateUiState({ ideaSearchPotentialEstimate: value }),
        [updateUiState],
    );

    const handleSearchTimeEstimateChange = useCallback(
        (value: (EstimatesValue | null)[]) => updateUiState({ ideaSearchTimeEstimate: value }),
        [updateUiState],
    );

    const handleSearchEffortEstimateChange = useCallback(
        (value: (EstimatesValue | null)[]) => updateUiState({ ideaSearchEffortEstimate: value }),
        [updateUiState],
    );

    const handleSearchStatusChange = useCallback((value: IdeaStatus[]) => updateUiState({ ideaSearchStatus: value }), [updateUiState]);

    const handleSearchFieldChange = useCallback(
        (key: string, value: (string | null)[]) => {
            updateUiState({
                ideaSearchFields: {
                    ...uiState.ideaSearchFields,
                    [key]: value,
                },
            });
        },
        [uiState.ideaSearchFields, updateUiState],
    );

    return (
        <Stack direction="row" alignItems="center" justifyContent="flex-end" flexWrap="wrap" spacing={1} rowGap={1}>
            <IdeaStatusFilter value={uiState.ideaSearchStatus} size="small" onChange={handleSearchStatusChange} />
            <IdeaUserSelectFilter
                size="small"
                value={uiState.ideaSearchCreatedByIds}
                label={translate(translationKeys.VDLANG_IDEAS_IDEA_CREATOR_LABEL)}
                onChange={handleSearchCreatorIdsChange}
            />
            <IdeaUserSelectFilter
                size="small"
                value={uiState.ideaSearchOwnedByIds}
                label={translate(translationKeys.VDLANG_IDEAS_IDEA_RESPONSIBLE_LABEL)}
                onChange={handleSearchOwnerIdsChange}
            />
            <IdeaEstimateFilter
                size="small"
                variant="potential"
                value={uiState.ideaSearchPotentialEstimate}
                onChange={handleSearchPotentialEstimateChange}
            />
            <IdeaEstimateFilter
                size="small"
                variant="time"
                value={uiState.ideaSearchTimeEstimate}
                onChange={handleSearchTimeEstimateChange}
            />
            <IdeaEstimateFilter
                size="small"
                variant="effort"
                value={uiState.ideaSearchEffortEstimate}
                onChange={handleSearchEffortEstimateChange}
            />
            {ideaAttributesQuery.data
                ?.filter((a) => VALID_FILTER_TYPES.includes(a.type))
                .map((attribute) => (
                    <IdeaFieldFilter
                        size="small"
                        key={attribute.title}
                        value={uiState.ideaSearchFields[attribute.title] ?? EMPTY_ARRAY}
                        attribute={attribute}
                        onChange={(value) => handleSearchFieldChange(attribute.title, value)}
                    />
                ))}
        </Stack>
    );
};

export default React.memo(IdeasViewFilterHeader);
