import { Divider, Skeleton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { IdeaDto } from "api-shared";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import MoneyChip from "../../components/MoneyChip";
import { useUiState } from "../../domain/ui-state";
import { translationKeys } from "../../translations/main-translations";
import IdeaSortSelect from "./IdeaSortSelect";
import { IIdeaSortSelectOption } from "./IdeasView";
import { useIdeaPotentialSum } from "./hooks";

interface IdeasSummaryGridProps {
    ideas: IdeaDto[];
    isFetching: boolean;
}

export const IdeasSummaryGrid = ({ children, ideas, isFetching }: PropsWithChildren<IdeasSummaryGridProps>) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDenseLayout = useMediaQuery(theme.breakpoints.down("md"));
    const [uiState, updateUiState] = useUiState();
    const ideaPotentialSum = useIdeaPotentialSum(ideas);

    const updateGridSortBy = (ideaSortBy: IIdeaSortSelectOption) => {
        ideaSortBy != null && updateUiState({ opportunitiesGridOrderBy: ideaSortBy.sortType, opportunitiesGridSort: ideaSortBy.sort });
    };

    if (isFetching) {
        return <Skeleton height={44} variant="rounded" />;
    }

    return (
        <Stack
            p={1.25}
            direction={isDenseLayout ? "column" : "row"}
            justifyContent="space-between"
            alignItems="center"
            sx={{ ml: 1 }}
            spacing={1}
        >
            <Stack direction={isDenseLayout ? "column" : "row"} alignItems="center" spacing={1}>
                <Typography align="center">
                    <strong>{ideas.length} </strong>
                    {t(translationKeys.VDLANG_IDEAS_ITEMS_LABEL)}
                </Typography>

                <Divider orientation={isDenseLayout ? "horizontal" : "vertical"} flexItem />

                <Stack direction="row" alignItems="center">
                    <Tooltip title={t(translationKeys.VDLANG_IDEAS_IDEA_POTENTIAL_TOTAL_HINT)}>
                        <Typography align="center" sx={{ mr: 0.5 }}>
                            <MoneyChip short approx value={ideaPotentialSum} sx={{ mr: 0.5 }} />
                            {t(translationKeys.VDLANG_IDEAS_IDEA_POTENTIAL)}
                        </Typography>
                    </Tooltip>
                    {children}
                </Stack>
                {isDenseLayout && (
                    <Stack direction="row" alignItems="center">
                        <IdeaSortSelect
                            value={{ sort: uiState.opportunitiesGridSort, sortType: uiState.opportunitiesGridOrderBy }}
                            onChange={updateGridSortBy}
                        />
                    </Stack>
                )}
            </Stack>
            {!isDenseLayout && (
                <Stack direction="row" alignItems="center">
                    <IdeaSortSelect
                        value={{ sort: uiState.opportunitiesGridSort, sortType: uiState.opportunitiesGridOrderBy }}
                        onChange={updateGridSortBy}
                    />
                </Stack>
            )}
        </Stack>
    );
};
