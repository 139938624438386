import SearchIcon from "@mui/icons-material/SearchRounded";
import { InputAdornment, styled } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import OutlinedIconButton from "../../components/OutlinedIconButton";
import Form from "../../components/Form";
import SearchInput from "../../components/input/SearchInput";
import { translationKeys } from "../../translations/main-translations";

const GreySearchIcon = styled(SearchIcon)(({ theme }) => ({
    color: theme.palette.fontLightGrey.main,
}));

type IdeaSearchInputProps = {
    value: string;
    onChange: (value: string) => void;
    searchError: boolean;
};

export const IdeaSearchInput = ({ value, onChange, searchError }: IdeaSearchInputProps) => {
    const { t: translate } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    if (!isOpen) {
        return (
            <OutlinedIconButton onClick={() => setIsOpen(true)} aria-label={translate(translationKeys.VDLANG_IDEAS_TOGGLE_SEARCH)}>
                <GreySearchIcon />
            </OutlinedIconButton>
        );
    }
    return (
        <Form role="search" aria-label={translate(translationKeys.VDLANG_SECTIONS_IDEAS)}>
            <SearchInput
                searchKey={value}
                onChange={onChange}
                translate={translate}
                onReset={() => setIsOpen(false)}
                error={searchError}
                autoFocus
                hideSearchIcon
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <GreySearchIcon />
                        </InputAdornment>
                    ),
                }}
                sx={{ minWidth: (theme) => theme.spacing(20) }}
            />
        </Form>
    );
};
