import AddIcon from "@mui/icons-material/AddRounded";
import MatrixViewIcon from "@mui/icons-material/AnalyticsOutlined";
import FilterIcon from "@mui/icons-material/FilterListRounded";
import TableViewIcon from "@mui/icons-material/TocRounded";
import GridViewIcon from "@mui/icons-material/ViewModuleRounded";
import { Button, Collapse, Stack, styled, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { OpportunityTab } from "api-shared";
import { useTranslation } from "react-i18next";
import { UnstyledLink } from "../../components/Link";
import { StaticBadge } from "../../components/StaticBadge";
import { useUiState } from "../../domain/ui-state";
import useDialog from "../../hooks/useDialog";
import { useIsDesktop } from "../../lib/mobile";
import { RouteFor } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";
import { IdeaSearchInput } from "./IdeaSearchInput";
import IdeasViewFilterHeader from "./IdeasViewFilterHeader";

const Header = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const IdeaButton = styled(Button)(({ theme }) => ({
    height: theme.spacing(4.5),
}));

const FilterToggleButton = styled(ToggleButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    borderColor: theme.palette.border.main,
    padding: theme.spacing(1, 1.5),
    height: "100%",
}));

interface IdeaHeaderProps {
    numberOfSearchParams: number;
    filterKey: string;
    setFilterKey: (key: string) => void;
    showSortControl: boolean;
    searchError: boolean;
}

export const IdeaHeader = ({ numberOfSearchParams, filterKey, setFilterKey, showSortControl, searchError }: IdeaHeaderProps) => {
    const { t } = useTranslation();
    const [uiState, updateUiState] = useUiState();
    const isDesktop = useIsDesktop();
    const filterToggle = useDialog();

    const handleTabSelect = (value: OpportunityTab) => {
        value !== null && updateUiState({ opportunitiesTab: value });
    };

    return (
        <>
            <Header sx={{ px: { xs: 2, md: 8 }, py: 1.75 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                        <Typography variant="h5" noWrap>
                            {t(translationKeys.VDLANG_IDEAS_NAVBAR_ITEM_LABEL)}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" flexShrink={0} spacing={1} sx={{ height: (theme) => theme.spacing(4.5) }}>
                        <IdeaSearchInput value={filterKey} searchError={searchError} onChange={setFilterKey} />
                        {isDesktop ? (
                            <UnstyledLink to={RouteFor.opportunities.forId(0)}>
                                <IdeaButton variant="contained" startIcon={<AddIcon />} data-testid="captureIdea_Button">
                                    {t(translationKeys.VDLANG_IDEAS_ADD_IDEA_BUTTON_LABEL)}
                                </IdeaButton>
                            </UnstyledLink>
                        ) : (
                            <UnstyledLink to={RouteFor.opportunities.forId(0)}>
                                <Button
                                    variant="contained"
                                    data-testid="captureIdea_Button"
                                    aria-label={t(translationKeys.VDLANG_IDEAS_ADD_IDEA_BUTTON_LABEL)}
                                    sx={{ minWidth: 0, px: 0.75 }}
                                >
                                    <AddIcon />
                                </Button>
                            </UnstyledLink>
                        )}
                        {isDesktop && (
                            <ToggleButtonGroup
                                size="small"
                                exclusive
                                value={uiState.opportunitiesTab}
                                onChange={(event, value) => handleTabSelect(value)}
                            >
                                <ToggleButton
                                    size="small"
                                    value={OpportunityTab.GRID}
                                    selected={uiState.opportunitiesTab === OpportunityTab.GRID}
                                    aria-label={t(translationKeys.VDLANG_IDEAS_TAB_GRID)}
                                >
                                    <GridViewIcon />
                                </ToggleButton>
                                <ToggleButton
                                    size="small"
                                    value={OpportunityTab.TABLE}
                                    selected={uiState.opportunitiesTab === OpportunityTab.TABLE}
                                    aria-label={t(translationKeys.VDLANG_IDEAS_TAB_TABLE)}
                                >
                                    <TableViewIcon />
                                </ToggleButton>
                                <ToggleButton
                                    size="small"
                                    value={OpportunityTab.MATRIX}
                                    selected={uiState.opportunitiesTab === OpportunityTab.MATRIX}
                                    data-testid="matrix-toggle"
                                    aria-label={t(translationKeys.VDLANG_IDEAS_TAB_MATRIX)}
                                >
                                    <MatrixViewIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        )}
                        <FilterToggleButton size="small" value={true} selected={filterToggle.isOpen} onChange={filterToggle.toggle}>
                            <Stack direction="row" spacing={1}>
                                <FilterIcon fontSize="small" />
                                <Typography variant="body2" textTransform="none">
                                    Filter
                                </Typography>
                                {numberOfSearchParams !== 0 && <StaticBadge badgeContent={numberOfSearchParams} color="primary" />}
                            </Stack>
                        </FilterToggleButton>
                    </Stack>
                </Stack>
            </Header>
            <Collapse in={filterToggle.isOpen}>
                <Header sx={{ px: { xs: 2, md: 8 }, py: 1 }}>
                    <IdeasViewFilterHeader translate={t} />
                </Header>
            </Collapse>
        </>
    );
};
