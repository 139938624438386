import EventIcon from "@mui/icons-material/EventRounded";
import { Card, CardActionArea, chipClasses, Skeleton, styled } from "@mui/material";
import { IdeaDto, IdeaStatus, ReactionType } from "api-shared";
import React from "react";
import { Link } from "react-router-dom";
import { MeasureBreadcrumb } from "../../components/MeasureBreadcrumb";
import MultilineGradientTypography from "../../components/MultilineGradientTypography";
import ReactionSummary from "../../components/reactions/ReactionSummary";
import Tooltip, { OverflowOrientation } from "../../components/Tooltip";
import { RouteFor } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";
import { useIdeaEstimateConversion } from "./hooks";
import IdeaIdChip from "./IdeaIdChip";

const IdeaReactionConfig = new Map([[ReactionType.Thumbsup, { icon: "👍", translationKey: translationKeys.VDLANG_REACTION_EMOJI_LIKE }]]);

export const classes = {
    row: "IdeaTile-row",
    title: "IdeaTile-title",
    chip: "IdeaTile-chip",
    potential: "IdeaTile-potential",
    date: "IdeaTile-dateText",
    spacer: "IdeaTile-spacer",
    content: "IdeaTile-content",
    reactions: "IdeaTile-reactions",
};

const IdeaCard = styled(Card)(({ theme }) => ({
    "& a, & a:focus": {
        // disable browser-default styling of links
        textDecoration: "none",
        color: "inherit",
    },
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: 0,
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create("all"),
    overflow: "visible", // let reactions summary stick out of card
    "&:hover": {
        boxShadow: theme.shadows[8],
        top: theme.spacing(-0.25),
        cursor: "pointer",
    },
    [`& .${classes.title}`]: {
        flexGrow: 1,
        flexShrink: 1,
        minWidth: 0,
        minHeight: "6em", // magic number to be in sync with 3-line clamp of title
    },
    [`& .${classes.potential}`]: {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.natureGreen.dark,
    },
    [`& .${classes.date}`]: {
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
    },
    [`& .${classes.row}`]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        [`& .${chipClasses.root}`]: {
            alignSelf: "flex-start",
        },
        flexWrap: "nowrap",
        gap: theme.spacing(),
    },
    [`& .${classes.spacer}`]: {
        flexGrow: 1,
    },
    [`& .${classes.content}`]: {
        padding: theme.spacing(2),
        // apply minHeight to content, so the parent link & button also expand to the full height
        minHeight: theme.spacing(16.5),
    },
    [`& .${classes.reactions}`]: {
        position: "absolute",
        bottom: theme.spacing(-1), // Do not center vertically, so that reactions do not touch idea tiles below
        left: theme.spacing(2),
    },
}));

interface IIdeaTile {
    idea?: IdeaDto;
}

const IdeaTile = ({ idea }: IIdeaTile) => {
    const getIdeaConversionValues = useIdeaEstimateConversion();

    if (idea === undefined) {
        return (
            <IdeaCard sx={{ overflow: "hidden" }}>
                <Skeleton variant="rectangular" width="100%" height={134} />
            </IdeaCard>
        );
    }
    const { potentialValue, timeValue } = getIdeaConversionValues({
        potentialEstimateValue: idea.potentialEstimate,
        timeEstimateValue: idea.timeEstimate,
        effortEstimateValue: idea.effortEstimate,
    });

    // Try to use as few styled or MUI components to avoid their runtime overhead
    // There may be many tiles be drawn at once, so trade re-usability/readability for performance here
    const content = (
        <CardActionArea>
            <div className={classes.content}>
                <div className={classes.row}>
                    <div className={classes.title}>
                        <Tooltip placement="bottom-start" title={idea.title} onlyOverflowing orientation={OverflowOrientation.Vertical}>
                            <MultilineGradientTypography lines={3} variant="body2">
                                {idea.title}
                            </MultilineGradientTypography>
                        </Tooltip>
                    </div>
                    {idea.referencedMeasure !== null ? (
                        <MeasureBreadcrumb ideaDisplayId={idea.displayId} measureDisplayId={idea.referencedMeasure.displayId} dense />
                    ) : (
                        <IdeaIdChip ideaId={idea.displayId} />
                    )}
                </div>
                <div className={classes.row}>
                    {idea.potentialEstimate != null ? <div className={classes.potential}>{potentialValue}</div> : null}
                    <div className={classes.spacer} />
                    {idea.timeEstimate != null ? (
                        <>
                            <EventIcon color="action" fontSize="small" />
                            <div className={classes.date}>{timeValue}</div>
                        </>
                    ) : null}
                </div>
            </div>
        </CardActionArea>
    );
    // Put Link as child to IdeaCard, so that the link can be styled by IdeaCard component
    return (
        <IdeaCard sx={{ opacity: idea.status === IdeaStatus.DISCARDED ? 0.5 : 1 }}>
            <Link to={RouteFor.opportunities.forId(idea.id)}>{content}</Link>
            {/* Render reactions outside link, so that navigation is not triggered when clicking the reactions chip */}
            {idea.reactions.length > 0 ? (
                <ReactionSummary
                    variant="chip"
                    className={classes.reactions}
                    allUserReactions={idea.reactions}
                    reactionConfig={IdeaReactionConfig}
                />
            ) : null}
        </IdeaCard>
    );
};

export default React.memo(IdeaTile);
