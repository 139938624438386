import { IdeaAttributeDto } from "api-shared";
import { isEqual } from "lodash";
import useCustomMemo from "../../../hooks/useCustomMemo";
import useFieldData from "../../../hooks/useFieldData";

export function useIdeaAttributeTranslations(attributes: IdeaAttributeDto[]) {
    const fieldData = useFieldData(
        attributes.map((attribute) => {
            return { ...attribute, options: attribute.options ?? {} };
        }),
        { fullTreePathValues: true },
    );

    return useCustomMemo(
        attributes.reduce((result: Record<string, { id: number; value: unknown }[]>, attribute, index) => {
            result[attribute.title] = fieldData[index].map((item) => ({
                id: item.id,
                value: item.name ?? null,
            }));
            return result;
        }, {}),
        isEqual,
    );
}
