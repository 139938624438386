import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { IconButton, iconButtonClasses, Menu, MenuItem, Paper, Stack, styled } from "@mui/material";
import { AclNamespaces, AclPermissions, FieldDefinitionsDto, IdeaAttributeDto, IdeaDto, IdeaFieldNames, Sort } from "api-shared";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { SortingRule } from "react-table";
import ExportCsvDialog from "../../components/dialogues/ExportCsvDialog";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import TableColumnConfigDialog from "../../components/table/TableColumnConfigDialog";
import { useIdeaFieldDefinitionsQuery } from "../../domain/filters";
import { useUserHasPermissionQuery } from "../../domain/permissions";
import { useUiState } from "../../domain/ui-state";
import { useUsersQuery } from "../../domain/users";
import useDialog from "../../hooks/useDialog";
import { IDEA_FIELD_PREFIX } from "../../translations/field-translations";
import { translationKeys } from "../../translations/main-translations";
import { useIdeaDataFormatter } from "./hooks/useIdeaDataFormatter";
import { IdeasSummary } from "./IdeasSummary";
import { IdeaTable } from "./IdeaTable";

const HoverPaper = styled(Paper)(() => ({
    ["@media (hover: hover)"]: {
        [`&:hover .${iconButtonClasses.root}`]: {
            opacity: 1,
        },
    },
}));

const BorderedIdeaTable = styled(IdeaTable)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const MoreMenuButton = styled(IconButton, { shouldForwardProp: (name) => name !== "isOpen" })<{ isOpen: boolean }>(({ theme, isOpen }) => ({
    ["@media (hover: hover)"]: {
        opacity: isOpen ? 1 : 0,
        transition: theme.transitions.create("opacity"),
    },
}));

const MoreMenuIcon = styled(MoreVertRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

interface IdeaSummaryTableProps {
    attributes: IdeaAttributeDto[];
    ideas: IdeaDto[];
    isFetching: boolean;
}

const IdeaSummaryTable = ({ attributes, ideas, isFetching }: IdeaSummaryTableProps) => {
    const { t } = useTranslation();
    const hasCSVExportPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.Api,
        permission: AclPermissions.Export,
        fact: {},
    });

    const fieldDefinitionsQuery = useIdeaFieldDefinitionsQuery();
    const fieldDefinitions = fieldDefinitionsQuery.data ?? ({} as FieldDefinitionsDto);
    const usersQuery = useUsersQuery();
    const { dataFormatter } = useIdeaDataFormatter(attributes, fieldDefinitions, usersQuery.data ?? []);

    const columnConfigDialog = useDialog();
    const exportCsvDialog = useDialog();

    const [uiState, updateUiState] = useUiState();
    const [columnConfig, setColumnConfig] = useState(uiState.ideaTableColumns);
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLElement | null>(null);

    if (!hasCSVExportPermissionQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const moreMenuIsOpen = Boolean(moreMenuAnchorEl);

    function openMoreMenu(event: MouseEvent<HTMLElement>) {
        setMoreMenuAnchorEl(event.currentTarget);
    }

    function closeMoreMenu() {
        setMoreMenuAnchorEl(null);
    }

    function updateColumns(columns: string[]) {
        setColumnConfig(columns);
    }

    function handleColumnConfigClick() {
        closeMoreMenu();
        columnConfigDialog.open();
    }

    function handleExportCsvClick() {
        closeMoreMenu();
        exportCsvDialog.open();
    }

    function saveColumns() {
        if (!columnConfig.includes(uiState.opportunitiesTableOrderBy)) {
            // Column after which was ordered is not visible anymore. Fall back to the first visible and sortable one.
            // If none of the columns is sortable, fall back to the title column.
            const newOrderBy = columnConfig.find((col) => fieldDefinitions[col]?.isSortable) ?? IdeaFieldNames.Title;
            updateUiState({ opportunitiesTableOrderBy: newOrderBy, ideaTableColumns: columnConfig });
        } else {
            updateUiState({ ideaTableColumns: columnConfig });
        }
    }

    function onSortByChanged(newSortBy: SortingRule<IdeaDto>) {
        updateUiState({
            opportunitiesTableOrderBy: newSortBy.id,
            opportunitiesTableSort: newSortBy.desc ? Sort.DESCENDING : Sort.ASCENDING,
        });
    }

    if (fieldDefinitions === undefined) {
        return null;
    }

    return (
        <Stack height="100%" component={HoverPaper}>
            <IdeasSummary ideas={ideas} isFetching={isFetching}>
                <div>
                    <MoreMenuButton
                        id="opps-more-menu-button"
                        aria-controls={moreMenuIsOpen ? "opps-more-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={moreMenuIsOpen ? "true" : undefined}
                        isOpen={moreMenuIsOpen}
                        onClick={openMoreMenu}
                    >
                        <MoreMenuIcon aria-label={t(translationKeys.VDLANG_IDEAS_TABLE_OPTIONS)} />
                    </MoreMenuButton>
                    <Menu
                        id="opps-more-menu"
                        aria-labelledby="opps-more-menu-button"
                        anchorEl={moreMenuAnchorEl}
                        open={moreMenuIsOpen}
                        onClose={closeMoreMenu}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuItem onClick={handleColumnConfigClick}>{t(translationKeys.VDLANG_COLUMN_CONFIGURATION)}</MenuItem>
                        {hasCSVExportPermissionQuery.data.hasPermission && (
                            <MenuItem onClick={handleExportCsvClick}>{t(translationKeys.VDLANG_EXPORT_CSV)}</MenuItem>
                        )}
                    </Menu>
                </div>
            </IdeasSummary>

            <BorderedIdeaTable
                data={ideas}
                attributes={attributes}
                columns={uiState.ideaTableColumns}
                defaultPageSize={uiState.ideaTablePageSize}
                fieldDefinitions={fieldDefinitions}
                onPaginationChanged={(_, pageSize) => updateUiState({ ideaTablePageSize: pageSize })}
                sort={uiState.opportunitiesTableSort}
                sortBy={uiState.opportunitiesTableOrderBy}
                onSortByChanged={onSortByChanged}
                isFetching={isFetching}
                pageSizeOptions={[25, 50, 100]}
            />

            <TableColumnConfigDialog
                open={columnConfigDialog.isOpen}
                onClose={columnConfigDialog.close}
                translate={t}
                columns={columnConfig}
                disabledColumns={[IdeaFieldNames.Title]}
                updateColumns={updateColumns}
                fieldDefinitions={fieldDefinitions}
                translationPrefix={IDEA_FIELD_PREFIX}
                onSave={saveColumns}
            />

            {hasCSVExportPermissionQuery.data.hasPermission && (
                <ExportCsvDialog
                    data={ideas}
                    dataFormatter={dataFormatter}
                    columns={uiState.ideaTableColumns}
                    columnsTranslationPrefix={IDEA_FIELD_PREFIX}
                    fieldDefinitions={fieldDefinitions}
                    fieldNames={Object.values(IdeaFieldNames)}
                    open={exportCsvDialog.isOpen}
                    onClose={exportCsvDialog.close}
                    primary="VDLANG_EXPORT_CSV_BUTTON"
                    trackEventName="Opps-Table"
                />
            )}
        </Stack>
    );
};

export default IdeaSummaryTable;
