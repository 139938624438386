import {
    AttributeType,
    DiscardReasons,
    ExportLocale,
    FieldDefinitionsDto,
    FieldTypes,
    IdeaAttributeDto,
    IdeaDto,
    Language,
    UserDto,
} from "api-shared";
import moment from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hooks/useLanguage";
import useRationalNumber from "../../../hooks/useRationalNumber";
import { findField } from "../../../lib/fields";
import { formatUserFromId, removeLineBreaks } from "../../../lib/formatters";
import { replaceImage } from "../../../lib/history";
import { replaceMentionUsers } from "../../../lib/mention";
import { useIdeaAttributeTranslations } from "./useIdeaAttributeTranslations";
import { useIdeaEstimateConversion } from "./useIdeaEstimates";
import { useIdeaStatusTranslation } from "./useIdeaStatusTranslation";

function formatDateForExport(value: unknown) {
    if (value == null) {
        return null;
    }
    return moment.utc(value).format("YYYY-MM-DD HH:mm:ss");
}

export function useIdeaDataFormatter(attributes: IdeaAttributeDto[], fieldDefinitions: FieldDefinitionsDto, users: UserDto[] = []) {
    const { t: translate } = useTranslation();
    const userLanguage = useLanguage();
    const conversion = useIdeaEstimateConversion();
    const attributeTranslations = useIdeaAttributeTranslations(
        attributes.filter((attribute) => attribute.type === AttributeType.Combobox || attribute.type === AttributeType.Tree),
    );
    const translateIdeaStatus = useIdeaStatusTranslation();
    const { formatRationalNumber } = useRationalNumber({ language: userLanguage });

    const formatNumberField = useCallback(
        (key: string, value: string | number | number[] | string[] | null, language: Language | ExportLocale) => {
            const fieldField = findField(attributes, fieldDefinitions, key);
            const maxDigits = fieldField?.options?.fractionDigits;
            return value != null ? formatRationalNumber(Number(value), { numberOfFractionalDigits: maxDigits, language }) : "";
        },
        [attributes, fieldDefinitions, formatRationalNumber],
    );

    const resolveIdeaFields = useCallback(
        (ideaFields: IdeaDto["fields"], language: ExportLocale): Record<string, unknown> => {
            const fields: Record<string, unknown> = {};
            Object.keys(ideaFields).forEach((key) => {
                const field = fieldDefinitions[key];
                const fieldValue = ideaFields[key];

                switch (field?.type) {
                    case FieldTypes.Single:
                    case FieldTypes.Set:
                        if (field?.attributeName === undefined) {
                            return;
                        }
                        fields[key] = attributeTranslations[key].find(({ id }) => id == ideaFields[key])?.value;
                        break;
                    case FieldTypes.Double:
                        fields[key] = formatNumberField(key, fieldValue, language);
                        break;
                    default:
                        // No other types are supported
                        return;
                }
            });
            return fields;
        },
        [attributeTranslations, fieldDefinitions, formatNumberField],
    );

    const dataFormatter = useCallback(
        (data: IdeaDto[], language: ExportLocale) => {
            return data.map((idea) => {
                const {
                    potentialValue: potentialEstimate,
                    timeValue: timeEstimate,
                    effortValue: effortEstimate,
                } = conversion({
                    potentialEstimateValue: idea.potentialEstimate,
                    timeEstimateValue: idea.timeEstimate,
                    effortEstimateValue: idea.effortEstimate,
                });

                const fields = resolveIdeaFields(idea.fields, language);

                const discardReason = idea.discardReason != null ? DiscardReasons.find(({ id }) => id == idea.discardReason) : null;
                const translatedDiscardReason = discardReason == null ? "" : discardReason[userLanguage];
                const ownerId =
                    idea.ownerId != null ? formatUserFromId(Number(idea.ownerId), users, { translate }) : translate("deleted_user");

                const description = removeLineBreaks(replaceImage(replaceMentionUsers(users, idea.description, translate)));

                const discardStatement =
                    idea.discardStatement != null ? replaceMentionUsers(users, idea.discardStatement, translate) : null;

                const {
                    reactions,
                    status,
                    createdAt,
                    lastModificationAt,
                    convertedAt,
                    discardedAt,
                    title,
                    ...ideaWithoutReactionsAndStatus
                } = idea;

                const reactionsCount = reactions.length;

                const translatedStatus = translateIdeaStatus(status);

                const ideaTitle = removeLineBreaks(title);

                return {
                    ...ideaWithoutReactionsAndStatus,
                    title: ideaTitle,
                    discardStatement,
                    discardReason: translatedDiscardReason,
                    description,
                    ownerId,
                    potentialEstimate,
                    timeEstimate,
                    effortEstimate,
                    reactionsCount,
                    status: translatedStatus,
                    createdAt: formatDateForExport(createdAt),
                    lastModificationAt: formatDateForExport(lastModificationAt),
                    convertedAt: formatDateForExport(convertedAt),
                    discardedAt: formatDateForExport(discardedAt),
                    ...fields,
                };
            });
        },
        [conversion, resolveIdeaFields, translate, translateIdeaStatus, userLanguage, users],
    );

    return {
        dataFormatter,
    };
}
