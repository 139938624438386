import { Divider, Grid, Skeleton, styled, Typography } from "@mui/material";
import { IdeaDto } from "api-shared";
import { times } from "lodash";
import IdeaTile from "./IdeaTile";

export interface IIdeaSortContainerRowProps {
    label?: React.ReactNode;
    ideaRow?: IdeaDto[];
}

const StyledDivider = styled(Divider)({
    // Avoid left-aligned child text to be offset by 10%
    "&:before": {
        width: 0,
    },
    "&:after": {
        width: "100%",
    },
});

const NUMBER_OF_SKELETON_TILES = 10;

const IdeaSortContainerRow = ({ label, ideaRow }: IIdeaSortContainerRowProps) => {
    const data = ideaRow ?? times(NUMBER_OF_SKELETON_TILES, () => undefined);
    return (
        <>
            <Grid item xs={12} marginY={0.5}>
                <StyledDivider textAlign="left">
                    <Typography variant="body2" color="textSecondary">
                        {label ?? <Skeleton width={200} />}
                    </Typography>
                </StyledDivider>
            </Grid>
            {data.map((idea, index) => (
                <Grid
                    item
                    key={idea?.id ?? -index} // use negative index to avoid key collision with actual idea ids
                    xs={12}
                    sm={6} // smaller cards
                    md={4} // smaller cards
                    xl={3} // smaller cards
                >
                    <IdeaTile idea={idea} />
                </Grid>
            ))}
        </>
    );
};

export default IdeaSortContainerRow;
