import { useEffect, useState } from "react";

/**
 * This hook is a workaround! Use with caution!
 * It memoizes data and only updates it when necessary. The necessity is determined by the compare function. Use it to
 * prevent unnecessary re-renders, e.g. when you need to pass memoized data into a useMemo hook.
 */
function useCustomMemo<T>(data: T, compareFunction: (a: T, b: T) => boolean) {
    const [memoizedData, setMemoizedData] = useState(data);

    useEffect(() => {
        if (!compareFunction(memoizedData, data)) {
            setMemoizedData(data);
        }
    }, [compareFunction, memoizedData, data]);

    return memoizedData;
}

export default useCustomMemo;
