import { Grid, styled, Typography } from "@mui/material";
import { IdeaDto, IdeaSortBy, nonNullable, OpportunityTab, SHORT_TEXT_MAX_CHARS, Sort, zShortText } from "api-shared";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BannerLayout from "../../components/BannerLayout";
import { useIdeaAttributesQuery, useIdeaQuery, useIdeaSearchQuery } from "../../domain/ideas";
import { useUiState } from "../../domain/ui-state";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { matomoTrackSiteSearch } from "../../infrastructure/tracking";
import { useFieldDataSubscription } from "../../lib/field-options";
import { useIsDesktop } from "../../lib/mobile";
import { NotificationType } from "../../lib/notifications";
import { RouteFor } from "../../lib/routes";
import NoIdeasYetIllustration from "../../static/images/empty_ideas.svg";
import { translationKeys } from "../../translations/main-translations";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";
import IdeaDialog from "./IdeaDialog";
import { IdeaHeader } from "./IdeaHeader";
import IdeaSortContainer from "./IdeaSortContainer";
import IdeaSummaryTable from "./IdeaSummaryTable";
import IdeasViewMatrix from "./IdeasViewMatrix";
import { useIdeaDebouncedSearchKey } from "./hooks/useIdeaDebouncedSearchKey";

const Content = styled("div")(({ theme }) => ({
    overflowX: "hidden",
    height: "100%",
}));

const ContentEmpty = styled(Grid)(({ theme }) => ({
    height: "100%",
    paddingBottom: theme.spacing(14),
}));

const Image = styled("img")(({ theme }) => ({
    padding: theme.spacing(5),
    maxWidth: "100%",
    maxHeight: "100%",
}));

export interface IIdeaSort {
    label: string;
    key: string;
    data: IdeaDto[] | undefined;
}

export interface IIdeaSortSelectOption {
    sortType: IdeaSortBy;
    sort: Sort;
}

const POLL_INTERVAL = 15000;

// eslint-disable-next-line sonarjs/cognitive-complexity
const IdeasView = () => {
    const { ideaId } = useParams<{ ideaId?: string }>();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useDocumentTitle(translationKeys.VDLANG_SECTIONS_IDEAS);
    const isDesktop = useIsDesktop();
    const [uiState] = useUiState();
    useFieldDataSubscription(); // fetch data for idea fields

    const { t } = useTranslation();

    const [filterKey, setFilterKey] = useState("");
    const searchKeyValid = zShortText.safeParse(filterKey).success;
    const debouncedSearchKey = useIdeaDebouncedSearchKey(searchKeyValid ? filterKey : filterKey.substring(0, SHORT_TEXT_MAX_CHARS));
    const attributesQuery = useIdeaAttributesQuery();
    const searchRequestBody = {
        query: debouncedSearchKey,
        potentialEstimate: uiState.ideaSearchPotentialEstimate,
        timeEstimate: uiState.ideaSearchTimeEstimate,
        effortEstimate: uiState.ideaSearchEffortEstimate,
        status: uiState.ideaSearchStatus,
        fields: uiState.ideaSearchFields,
        creatorIds: uiState.ideaSearchCreatedByIds,
        ownerIds: uiState.ideaSearchOwnedByIds,
    };
    const ideasQuery = useIdeaSearchQuery(searchRequestBody, {
        // only poll when not viewing a single idea, i.e. idea dialog is not open
        refetchInterval: ideaId === undefined ? POLL_INTERVAL : undefined,
        onSuccess: (data) => {
            if (debouncedSearchKey.length > 0 && ideasQuery.isPreviousData) {
                // only track search if there is a search key
                // on refetch, the isPreviousData is always false => no tracking for refetching
                matomoTrackSiteSearch(debouncedSearchKey, "opp-space", data.length);
            }
        },
    });
    const ideaQuery = useIdeaQuery(ideaId != undefined && +ideaId > 0 ? +ideaId : null);

    const numberOfSearchParams = [
        uiState.ideaSearchPotentialEstimate,
        uiState.ideaSearchTimeEstimate,
        uiState.ideaSearchEffortEstimate,
        uiState.ideaSearchStatus,
        ...Object.values(uiState.ideaSearchFields),
        uiState.ideaSearchCreatedByIds,
    ]
        .filter(nonNullable)
        .filter((params) => params?.length).length;

    const hasSearch = debouncedSearchKey.length > 0 || numberOfSearchParams > 0;

    const handleClose = useCallback(() => {
        navigate(RouteFor.opportunities.view);
    }, [navigate]);

    // We need to check for ideaId === 0, so that new ideas can be created
    const ideaIdIsValid =
        ideaId !== undefined && ideasQuery.data !== undefined && ideasQuery.data !== undefined
            ? ideasQuery.data.some((idea) => idea.id === +ideaId) || +ideaId === 0 || ideaQuery.data?.id === +ideaId
            : undefined;

    useEffect(() => {
        if (ideaQuery.isFetching) {
            return;
        }
        if (!searchKeyValid) {
            dispatch(showNotificationEvent(NotificationType.ERROR, FeedbackTranslationKeys.VDLANG_FEEDBACK_SEARCH_TOO_LONG));
        }
    }, [
        dispatch,
        filterKey,
        ideaId,
        ideaIdIsValid,
        ideaQuery.isFetching,
        ideasQuery.isFetching,
        ideasQuery.isLoading,
        navigate,
        searchKeyValid,
    ]);

    const isGridView = useMemo(() => uiState.opportunitiesTab === OpportunityTab.GRID, [uiState]);
    const isMatrixView = useMemo(() => uiState.opportunitiesTab === OpportunityTab.MATRIX, [uiState]);

    const isFetching = ideasQuery.isFetching && !ideasQuery.isRefetching;

    const renderContent = () => {
        if (ideasQuery.data?.length === 0) {
            return (
                <ContentEmpty container direction="column" alignItems="center" justifyContent="center">
                    <Image src={NoIdeasYetIllustration} alt="" />
                    <Typography variant="body2" color="textSecondary">
                        {hasSearch ? t(translationKeys.VDLANG_IDEAS_SEARCH_RESULT) : t(translationKeys.VDLANG_IDEAS_NO_IDEAS_YET)}
                    </Typography>
                </ContentEmpty>
            );
        } else if (isMatrixView && isDesktop) {
            return <IdeasViewMatrix ideas={ideasQuery.data ?? []} isFetching={isFetching} />;
        } else if (isGridView) {
            return (
                <IdeaSortContainer
                    filteredIdeas={ideasQuery.data ?? []}
                    orderBy={uiState.opportunitiesGridOrderBy}
                    sort={uiState.opportunitiesGridSort}
                    isFetching={!ideasQuery.isSuccess}
                />
            );
        } else if (attributesQuery.isSuccess) {
            return <IdeaSummaryTable attributes={attributesQuery.data} ideas={ideasQuery.data ?? []} isFetching={isFetching} />;
        }
        return false; // default return in case none of the conditions match
    };

    return (
        <BannerLayout
            banner={
                <IdeaHeader
                    numberOfSearchParams={numberOfSearchParams}
                    filterKey={filterKey}
                    setFilterKey={setFilterKey}
                    searchError={!searchKeyValid}
                    showSortControl={isGridView}
                />
            }
        >
            <Content sx={{ px: { xs: 2, md: 8 }, py: { xs: 2, md: 4 } }}>
                {ideasQuery.isSuccess && ideaId !== undefined && (
                    <IdeaDialog
                        key={`idea-${ideaId}`}
                        open={ideaIdIsValid === true}
                        onClose={handleClose}
                        currentIdea={ideasQuery.data.find((idea) => idea.id === +ideaId) ?? ideaQuery.data ?? null}
                        searchQuery={searchRequestBody}
                    />
                )}
                {renderContent()}
            </Content>
        </BannerLayout>
    );
};

export default React.memo(IdeasView);
