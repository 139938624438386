import { alpha, styled, Theme, Typography, typographyClasses, TypographyVariant } from "@mui/material";
import React, { HTMLProps } from "react";

const getLineHeightFromTypography = (theme: Theme, variant: TypographyVariant) => {
    const height =
        theme.typography.htmlFontSize *
        +String(theme.typography[variant].fontSize).replace(/rem$/, "") *
        Number(theme.typography[variant].lineHeight);
    return !Number.isNaN(height) ? height : 20; // 14 won't actually happen, but resemble the lineHeight for body2 variant
};

interface CommonProps {
    lines: number;
    variant: TypographyVariant;
}

const TextOverflowWrapper = styled("div", { shouldForwardProp: (name) => name !== "lines" && name !== "variant" })<CommonProps>(({
    theme,
    lines,
    variant,
}) => {
    const lineHeight = getLineHeightFromTypography(theme, variant);
    const totalHeight = lineHeight * lines;

    return {
        height: `${totalHeight}px`,
        overflow: "hidden",
        wordBreak: "break-all",
        [`& .${typographyClasses.root}`]: {
            position: "relative",
            //maxHeight needs to be bigger for Gradient to be shown
            maxHeight: `${lineHeight * (lines + 1)}px`,
            "&::after": {
                content: '" "',
                display: "block",
                position: "absolute",
                right: 0,
                bottom: 0,
                left: 0,
                // See https://stackoverflow.com/questions/50134444/fadeout-last-line-of-text-if-more-than-n-lines
                // We want to add a gradient if more than X lines would be used. The height of this
                // pseudo element is calculated based on the number of lines.
                // If there are X or less lines, the height gets negative and is set to 0 by the browser.
                // Otherwise we get a finite height and can apply the gradient.
                height: `calc(100% - ${totalHeight}px)`,
                transform: `translateY(-${lineHeight}px)`,
                background: `linear-gradient(${alpha(theme.palette.background.paper, 0)}, ${
                    theme.palette.background.paper
                } ${lineHeight}px)`,
            },
        },
    };
});

interface MultilineGradientTypographyProps extends Omit<HTMLProps<HTMLDivElement>, "as">, CommonProps {}

/*
This component renders a Typography text that is faded out with a gradient on overflow.
It takes a Typography variant and number of lines that should be shown as parameters.
*/
const MultilineGradientTypography = React.forwardRef<HTMLDivElement, MultilineGradientTypographyProps>(
    ({ lines, variant, children, ...divProps }, ref) => {
        // pass ref + remaining props to root component, so this component can be used as a Tooltip child
        return (
            <TextOverflowWrapper lines={lines} variant={variant} ref={ref} {...divProps}>
                <Typography component="div" variant={variant}>
                    {children}
                </Typography>
            </TextOverflowWrapper>
        );
    },
);

export default MultilineGradientTypography;
